<template>
    <div class="adv">

        <div class="adv__slice adv__slice--hero">
            <div class="adv__slice-element adv__slice-element--first">
                <h1 class="adv__slice-title">Sei utente welfare aziendale?</h1>
                <p class="adv__slice-desc">
                    Scopri come utilizzare il tuo credito con Agenzia viaggi by Vivamod, un servizio viaggi legato al mondo del welfare.
                </p>
            </div>
            <div class="adv__slice-element adv__slice-element--second">
                <img src="assets/images/adv/agenzia_viaggi_vivamod/squash.png" />
            </div> 
            <ScrollTo selector="#scroll" />   
        </div>

        <div class="adv__slice adv__slice--text text-center">
            <p class="adv__slice-desc">
                Agenzia viaggi by Vivamod è un portale on line di prenotazione viaggi per il welfare aziendale studiato in collaborazione con Edenred.<br/><br/>

                Per accedere al servizio, sarà necessario effettuare il login tramite il proprio portale welfare. In questo modo potrai
                scoprire e valutare le nostre proposte di viaggio,
                direttamente dalla homepage.
            </p>
        </div>

        <div class="adv__slice adv__slice--deco adv__slice--deco-01">
            <div class="adv__slice-element adv__slice-element--first">
                <h2 class="adv__slice-title">Un portale di prenotazione viaggi semplice e sicuro</h2>
                <p class="adv__slice-desc">
                    Vivamod è stato pensato per rendere la prenotazione di viaggi semplice e sicura. Verrai infatti seguito da operatori esperti che ti aiuteranno a trovare il soggiorno più adatto a te.<br/><br/>
                    Accedi al sito tramite il tuo portale welfare e inizia il tuo percorso. Consulta le soluzioni disponibili in tutto il mondo!<br/><br/>
                    <a class="adv__slice-link">Scopri di più accedendo tramite welfare!</a>
                </p>
            </div>
            <div class="adv__slice-element adv__slice-element--second">
                <img src="assets/images/adv/agenzia_viaggi_vivamod/vivamod_home.png" />
            </div> 
        </div>

    </div>
</template>

<script>

    import ScrollTo from '@/components/atoms/scroll-to'

    export default {
        name: 'adv',
        metaInfo: {
            title: 'Adv',
        },

        components: {
            ScrollTo
        },

        data() {
            return {
                site: this.appConfig().site,
                businessVivamodHost: process.env.VUE_APP_BUSINESS_VIVAMOD_HOST,
            }
        },
        mounted() {

        },
        methods:{

        }
    }

</script>

<style lang="scss">
    
    @import '@/scss/_vivamod.scss';

    .page-adv {
        
        @include media-breakpoint-up(lg) {
            background-color: $white;
        }

        main {
            padding-top: 150px;

            @include media-breakpoint-up(lg)
            {
                padding-top: 250px;
            }

            @include media-breakpoint-up(xl)
            {
                padding-top: 200px;
            }

            @include media-breakpoint-up(xxl)
            {
                padding-top: 100px;
            }
        }

        footer {
            & > .container-fluid {
                display: none;
            }
        }
    }

    
    .adv {
        padding: 0 50px;
        
        &__slice {
            $this: &;
            margin-bottom: 100px;


            // SLICE HERO 
            
            &--hero {

                @include media-breakpoint-up(lg) {
                    display: flex;
                    align-items: center;
                    position: relative;
                    margin-bottom: 180px;
                }

                &:before {
                    @include media-breakpoint-up(xxl) {
                        content: '';
                        background: url("../../../../../public/assets/images/adv/agenzia_viaggi_vivamod/pills.png") no-repeat top left;
                        background-size: contain;
                        width: 100%;
                        height: 700px;
                        position: absolute;
                        top: -30%;
                        left: 70px;
                        z-index: -1;
                        opacity: .7;
                    }
                }

                &:after {
                    @include media-breakpoint-up(xxl) {
                        $size: 150px;
                        content: '';
                        width: $size;
                        height: $size; 
                        background-color: #EDF0F7;
                        border-radius: 50%;
                        position: absolute;
                        bottom: -50px;
                        left: 30%;
                    }
                }

                .scrollTo {
                    position: absolute;
                    bottom: -70px;
                    left: 0;
                    right: 0;
                    margin: auto;
                    display: none;

                    @include media-breakpoint-up(lg) {
                        display: block;
                    }
                }

                #{$this}-element {

                    &--first {

                        @include media-breakpoint-up(lg) {
                            flex-shrink: 1;
                            flex-basis: 820px;
                        }
                        
                        @include media-breakpoint-up(xl) {
                            flex-shrink: 2;
                            flex-basis: 920px;
                        }

                        @include media-breakpoint-up(xxl) {
                            flex-shrink: 2;
                            flex-basis: 1000px;
                        }
                    }
                    &--second {

                        @include media-breakpoint-up(lg) {
                            flex-shrink: 2;
                            flex-basis: 1120px;
                        }

                        @include media-breakpoint-up(xl) {
                            flex-shrink: 1;
                            flex-basis: 720px;
                        }
                        

                        @include media-breakpoint-up(xxl) {
                            flex-shrink: 1;
                            flex-basis: 1200px;
                        }
                    }
                }

                #{$this}-desc {
                    font-weight: 400;
                    font-size: 32px;
                }
            }

            
            // SLICE DECORATION 01

            &--deco {

                &-01{
                    
                    border-radius: 50px;
                    position: relative;

                    @include media-breakpoint-up(sm) {
                        background-color: $gray-100;
                        padding: 40px;
                    }

                    @include media-breakpoint-up(xxl) {
                        display: flex;
                        align-items: center;
                        padding: 100px;
                    }

                    #{$this}-element {

                        &--first {

                            @include media-breakpoint-up(xxl) {
                                flex-basis: 900px;
                                flex-shrink: 2;
                            }
                        }

                        &--second {
                            
                            @include media-breakpoint-up(xxl) {
                                flex-basis: 1020px;
                                flex-shrink: 1;
                                margin-right: -200px;
                            }
                        }
                    }

                    #{$this}-desc {
                        font-size: 28px;
                    }

                    &:before {
                        @include media-breakpoint-up(xxl) {
                            content: '';
                            background: url("../../../../../public/assets/images/adv/agenzia_viaggi_vivamod/pills.png") no-repeat top left;
                            background-size: contain;
                            width: 100%;
                            height: 700px;
                            position: absolute;
                            top: -60px;
                            left: -23%;
                            z-index: 1;
                            opacity: .5;
                        }
                    }

                    &:after {
                        @include media-breakpoint-up(xxl) {
                            $size: 190px;
                            content: '';
                            width: $size;
                            height: $size; 
                            background-color: #EDF0F7;
                            border-radius: 50%;
                            position: absolute;
                            bottom: 180px;
                            left: -140px;
                        }
                    }
                }
            }


            // SLICE TEXT

            &--text {
            
                @include media-breakpoint-up(xxl) {
                    padding: 0 400px;
                }

                #{$this}-desc {
                    font-size: 32px;
                }
            }


            // COMMON ELEMENT

            &-title {
                color: $black;
                font-size: 53px;
                font-weight: 500;
            }

            &-desc {
                color: #9D9D9C;
            }
        }
    }
    
</style>